export interface Dot {
  project: string
  x: number
  y: number
}

const dots: Dot[] = [
  {
    project: 'conversation-series',
    x: 52.1,
    y: 30,
  },
  {
    project: 'internal-windows',
    x: 37.8,
    y: 73,
  },
  {
    project: 'ofset-chair',
    x: 0,
    y: 48,
  },
  {
    project: 'seat-prop',
    x: 89.7,
    y: 12.2,
  },
  {
    project: 'garment',
    x: 52.1,
    y: 50.8,
  },
  {
    project: 'blue-velvet-colonnade',
    x: 59.9,
    y: 24.4,
  },
  {
    project: 'interpersona',
    x: 79.7,
    y: 23.2,
  },
  {
    project: 'duct-taped-blankets',
    x: 23.7,
    y: 57.2,
  },
  {
    project: 'chroma-columns',
    x: 31.1,
    y: 28.5,
  },
  {
    project: 'an-alternative-sample-room',
    x: 23.5,
    y: 39.4,
  },
  {
    project: 'doric-columns-kinetic-object',
    x: 89.7,
    y: 12.2,
  },
  {
    project: 'a-softly-winded-chair',
    x: 99.1,
    y: 7.9,
  },
  {
    project: 'strap',
    x: 10.3,
    y: 72.3,
  },
  {
    project: 'coalesce',
    x: 99.1,
    y: 11,
  },
  {
    project: 'living-room',
    x: 61.3,
    y: 19.7,
  },
  {
    project: 'shed',
    x: 64.4,
    y: 24.4,
  },
  {
    project: 'salamandre',
    x: 47.5,
    y: 10.5,
  },
  {
    project: 'if-i-had-wings',
    x: 0,
    y: 22,
  },
  {
    project: 'wave-zero',
    x: 61.3,
    y: 13.7,
  },
  {
    project: 'dressed-up',
    x: 33.4,
    y: 28.5,
  },
  {
    project: 'bola',
    x: 42.8,
    y: 28.5,
  },
  {
    project: 'sundays',
    x: 89.7,
    y: 12.2,
  },
  {
    project: 'bumpy-basket',
    x: 42.8,
    y: 85,
  },
  {
    project: 'knit-together',
    x: 78.3,
    y: 10,
  },
  {
    project: 'berg',
    x: 39.9,
    y: 43.9,
  },
  {
    project: 'hammock-chair',
    x: 47.5,
    y: 10.5,
  },
  {
    project: 'a-trifle-of-colour',
    x: 61.3,
    y: 19.7,
  },
  {
    project: 'orillon',
    x: 61.3,
    y: 28,
  },
]

export default dots
