import { HoverHandlers, motion } from 'framer-motion'
import React, { FunctionComponent, MouseEvent } from 'react'
import Colors from '../../../tokens/Colors'
import { Link } from 'gatsby'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'

export interface ButtonProps {
  className?: string
  label: string
  onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
  onHoverEnd?: HoverHandlers['onHoverEnd']
  onHoverStart?: HoverHandlers['onHoverStart']
  to?: string
}

const Button: FunctionComponent<ButtonProps> = ({
  className,
  label,
  to,
  ...props
}) =>
  to ? (
    <StyledButton as={Link} className={className} to={to} {...props}>
      {label}
    </StyledButton>
  ) : (
    <StyledButton className={className} {...props}>
      {label}
    </StyledButton>
  )

const StyledButton = styled(motion.button)`
  font-size: ${rem(15)};
  font-weight: 700;
  background: ${Colors.WHITE};
  text-decoration: none;
  color: ${Colors.SHIP_GREY};
  padding: 0 ${rem(24)};
  line-height: ${rem(40)};
  height: ${rem(40)};
  border-radius: ${rem(20)};
  display: inline-block;
  transition: background 200ms ease-out;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    transition: background 200ms ease-in;
    background: ${Colors.ALTO};
  }

  .hide-cursor & {
    cursor: none;
  }
`

export default Button
