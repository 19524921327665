import React, {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Actions } from '../../../contexts/app-state/AppStateReducer'
import Button from '../../atoms/button/Button'
import Colors from '../../../tokens/Colors'
import Loader from '../../atoms/loader/Loader'
import { mediaQueries } from '../../../utils/mediaQueries'
import { motion } from 'framer-motion'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'
import { useAppState } from '../../../contexts/app-state/AppStateContext'
import useBreakPoints from '../../../hooks/use-breakpoints/useBreakPoints'
import useDictionary from '../../../hooks/use-dictionary/useDictionary'
import useLockBodyScroll from '../../../hooks/use-lock-body-scroll/useLockBodyScroll'

interface IntroVideoProps {
  className?: string
}

const IntroVideo: FunctionComponent<IntroVideoProps> = ({ className }) => {
  const [, dispatch] = useAppState()
  const landscapeVideo =
    'https://player.vimeo.com/external/449631314.hd.mp4?s=b4194e7d4c1c20b6f36bce98258d79b9f1e2284d&profile_id=175'
  const portraitVideo =
    'https://player.vimeo.com/external/449631284.hd.mp4?s=e8c1c0c01ab43c861dd26d94c23089e96e83ecdf&profile_id=175'
  const [isPlaying, setIsPlaying] = useState(false)
  const { isTablet } = useBreakPoints()
  const dictionary = useDictionary()
  const [videoSource, setVideoSource] = useState(landscapeVideo)

  useLockBodyScroll()

  /**
   * Video reference.
   */
  const videoRef = useRef<HTMLVideoElement | null>(null)

  /*
   * Set video source based on breakpoint
   * */
  useLayoutEffect(() => {
    if (videoRef.current) {
      setVideoSource(isTablet ? landscapeVideo : portraitVideo)
      videoRef.current.load()
    }
  }, [isTablet, videoRef, landscapeVideo, portraitVideo])

  const onSkipIntroButtonClick = useCallback((): void => {
    dispatch({
      payload: true,
      type: Actions.SET_IS_INTRO_VIEWED,
    })
  }, [dispatch])

  const onVideoEnded = useCallback((): void => {
    dispatch({
      payload: true,
      type: Actions.SET_IS_INTRO_VIEWED,
    })
  }, [dispatch])

  const onVideoIsPlaying = useCallback(() => {
    setIsPlaying(true)
  }, [])

  return (
    <StyledIntroVideo
      animate={{ opacity: 1 }}
      className={className}
      exit={{ opacity: 0 }}
      initial={{ opacity: 1 }}
    >
      <Video
        autoPlay={true}
        controls={false}
        muted={true}
        onEnded={onVideoEnded}
        onPlay={onVideoIsPlaying}
        playsInline={true}
        ref={videoRef}
      >
        <source src={videoSource} type="video/mp4" />
      </Video>
      <StyledButton
        label={dictionary.get('skipIntro')}
        onClick={onSkipIntroButtonClick}
      />
      {!isPlaying && <StyledLoader />}
    </StyledIntroVideo>
  )
}

const StyledIntroVideo = styled(motion.div)`
  background: ${Colors.BLACK};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledButton = styled(Button)`
  margin-bottom: ${rem(32)};
  position: relative;

  ${mediaQueries.tablet} {
    margin-bottom: ${rem(40)};
  }
`

const Video = styled.video`
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`

export default IntroVideo
