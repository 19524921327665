export const projectCardAnimation = {
  initial: {
    opacity: 0,
    y: 80,
  },
  opacityIn: {
    opacity: 1,
    transition: {
      duration: 0.27,
      ease: 'linear',
    },
  },
  transformIn: {
    y: 0,
    transition: {
      duration: 0.27,
      ease: 'easeInOut',
    },
  },
  opacityOut: {
    opacity: 0,
    transition: {
      duration: 0.27,
      ease: 'linear',
    },
  },
  transformOut: {
    y: 80,
    transition: {
      duration: 0.27,
      ease: 'easeInOut',
    },
  },
}
