import React, { FunctionComponent, useCallback, useState } from 'react'
import Title, { TitleProps, TitleSizes } from '../title/Title'
import Colors from '../../../tokens/Colors'
import { ImageNodeData } from '../../../contexts/images/ImagesContext'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { mediaQueries } from '../../../utils/mediaQueries'
import { motion } from 'framer-motion'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'
import useImageData from '../../../hooks/use-image-data/useImageData'
import { projectCardAnimation } from './animations'

interface ProjectCardProps {
  artist: string
  className?: string
  onHoverEnd?: () => void
  onHoverStart?: () => void
  slug: string
  title: string
}

const ProjectCard: FunctionComponent<ProjectCardProps> = ({
  className,
  title,
  artist,
  slug,
  ...props
}) => {
  const [hover, setHover] = useState(false)
  const image = useImageData(`cards/${slug}.jpg`) as ImageNodeData

  const onHoverStart = useCallback((): void => {
    props.onHoverStart && props.onHoverStart()
    setHover(true)
  }, [])

  const onHoverEnd = useCallback((): void => {
    props.onHoverEnd && props.onHoverEnd()
    setHover(false)
  }, [])

  return (
    <StyledProjectCard
      className={className}
      onHoverEnd={onHoverEnd}
      onHoverStart={onHoverStart}
      variants={projectCardAnimation}
    >
      <StyledLink to={`/projects/${slug}`}>
        {image && (
          <ImageWrapper
            animate={{ scale: hover ? 1.06 : 1 }}
            transition={{ ease: hover ? 'easeOut' : 'easeIn' }}
          >
            {image.fixed && (
              <Img
                fixed={image.fixed}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </ImageWrapper>
        )}
        <Overlay
          animate={{ opacity: hover ? 0.4 : 0.2 }}
          transition={{ ease: hover ? 'easeOut' : 'easeIn' }}
        />
        <Content>
          <StyledTitle
            responsiveSizes={{
              desktop: TitleSizes.large,
            }}
            size={TitleSizes.small}
          >
            {title}
          </StyledTitle>
          <StyledTitle level="h2" size={TitleSizes.xSmall}>
            <span dangerouslySetInnerHTML={{ __html: artist }} />
          </StyledTitle>
        </Content>
      </StyledLink>
    </StyledProjectCard>
  )
}

const StyledProjectCard = styled(motion.section)`
  background: ${Colors.SHIP_GREY};
  margin: 0 ${rem(-16)} ${rem(16)};
  height: ${rem(200)};
  display: flex;
  overflow: hidden;
  position: relative;

  ${mediaQueries.tablet} {
    margin: 0 0 ${rem(16)};
  }

  ${mediaQueries.desktop} {
    height: ${rem(224)};
  }
`

const StyledLink = styled(Link)`
  flex: 1;
  text-decoration: none;
  width: 100%;
`

const ImageWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${rem(16)};

  ${mediaQueries.desktop} {
    padding: ${rem(24)};
  }
`

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${Colors.BLACK};
  opacity: 0.2;
`

const StyledTitle = styled(Title)<TitleProps>`
  color: ${Colors.WHITE};

  ${mediaQueries.desktop} {
    margin: 0 0 ${rem(4)};
  }
`

export default ProjectCard
