// Hook
import { useLayoutEffect } from 'react'

// https://usehooks.com/useLockBodyScroll/
const useLockBodyScroll = (): void => {
  useLayoutEffect((): (() => void) => {
    // Prevent scrolling on mount
    document.body.style.overflow = 'hidden'
    // Re-enable scrolling when component unmounts
    return (): void => {
      document.body.style.overflow = ''
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}

export default useLockBodyScroll
