import { Variants } from 'framer-motion'

export const mapAnimation: Variants = {
  initial: {
    scale: 1.1,
    opacity: 0,
  },
  opacityIn: {
    opacity: 1,
    transition: {
      duration: 0.17,
      ease: 'linear',
    },
  },
  transformIn: {
    scale: 1,
    transition: {
      duration: 0.3,
      ease: 'linear',
    },
  },
}

export const dotAnimation: Variants = {
  initial: {
    scale: 1,
  },

  transformIn: {
    scale: 2,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  transformOut: {
    scale: 1,
    transition: {
      duration: 0.5,
      ease: 'easeIn',
    },
  },
}

export const pulseAnimation: Variants = {
  initial: {
    scale: 0,
  },
  transformIn: {
    scale: 5,
    transition: {
      duration: 2,
      ease: 'easeOut',
      repeatType: 'loop',
      repeat: Infinity,
    },
  },
  transformOut: {
    scale: 0,
    transition: {
      duration: 1,
      ease: 'easeIn',
    },
  },
  opacityIn: {
    opacity: [1, 0],
    transition: {
      duration: 2,
      ease: 'easeOut',
      repeatType: 'loop',
      repeat: Infinity,
    },
  },
  opacityOut: {
    opacity: 0,
    transition: {
      duration: 1,
      ease: 'easeIn',
    },
  },
}
