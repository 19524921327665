import React, { FunctionComponent } from 'react'
import Colors from '../../../tokens/Colors'
import { loaderAnimation } from './animations'
import { motion } from 'framer-motion'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'

interface LoaderProps {
  className?: string
  duration?: number
}

const Loader: FunctionComponent<LoaderProps> = ({
  className,
  duration = 6,
}) => (
  <StyledLoader
    animate={['left', 'right']}
    className={className}
    initial="left"
    transition={{
      duration,
      ease: 'easeOut',
      repeat: 2,
    }}
    variants={loaderAnimation}
  />
)

const StyledLoader = styled(motion.div)`
  width: 100%;
  height: ${rem(3)};
  background-color: ${Colors.WHITE};
`

export default Loader
