import { AnimatePresence, motion } from 'framer-motion'
import { grid, gridColumnsRoot } from '../../utils/grid'
import React, { FunctionComponent, useCallback, useState } from 'react'
import Colors from '../../tokens/Colors'
import { Helmet } from 'react-helmet'
import IntroVideo from '../molecules/intro-video/IntroVideo'
import Map from '../atoms/map/Map'
import { mediaQueries } from '../../utils/mediaQueries'
import MetaData from '../../types/MetaData'
import ProjectCard from '../atoms/project-card/ProjectCard'
import { ProjectProps } from './ProjectPage'
import { rem } from '../../utils/rem'
import styled from 'styled-components'
import { useAppState } from '../../contexts/app-state/AppStateContext'
import useImageData from '../../hooks/use-image-data/useImageData'

interface PageProps {
  pageContext: {
    meta: MetaData
    projects: ProjectProps[]
    route: string
  }
}

export const mobileHeaderHeight = 64
export const mapMargins = 2 * 32

const projectListAnimation = {
  opacityIn: {
    transition: {
      staggerChildren: 0.1,
      delay: 0.3,
    },
  },
  transformIn: {
    transition: {
      staggerChildren: 0.1,
      delay: 0.13,
    },
  },
}

const HomePage: FunctionComponent<PageProps> = ({ pageContext }) => {
  const { projects, meta } = pageContext
  const ogImage = useImageData(meta.og?.image)
  const [mapHeight, setMapHeight] = useState(0)
  const [{ isIntroViewed, projects: stateProjects }] = useAppState()
  const [hoveredProject, setHoveredProject] = useState<string | null>(null)

  const onMapHeightChange = useCallback((height: number) => {
    setMapHeight(height)
  }, [])

  return (
    <StyledHomePage>
      <Helmet>
        <title>{meta.title}</title>
        <meta content={meta.description} name="description" />
        <meta content={meta.og?.title || meta.title} name="og:title" />
        <meta
          content={meta.og?.description || meta.description}
          name="og:description"
        />
        {ogImage?.fixed?.src && (
          <meta content={meta.siteUrl + ogImage.fixed.src} name="og:image" />
        )}
      </Helmet>
      <Map activeProject={hoveredProject} onHeightChange={onMapHeightChange} />
      <Grid>
        {isIntroViewed ? (
          <ProjectList
            animate={['opacityIn', 'transformIn']}
            exit={['opacityOut', 'transformOut']}
            mapHeight={mapHeight}
            variants={projectListAnimation}
          >
            {(stateProjects || projects).map((project) => (
              <ProjectCard
                {...project}
                key={project.slug}
                onHoverEnd={(): void => setHoveredProject(null)}
                onHoverStart={(): void => setHoveredProject(project.slug)}
              />
            ))}
          </ProjectList>
        ) : (
          projects.map((project) => (
            <ProjectCard {...project} key={project.slug} />
          ))
        )}
      </Grid>
      <AnimatePresence>{!isIntroViewed && <IntroVideo />}</AnimatePresence>
    </StyledHomePage>
  )
}

const StyledHomePage = styled.div`
  background: ${Colors.BLACK};
  min-height: 100vh;
`

const Grid = styled.div`
  ${grid()};
  position: relative;
  padding-top: ${rem(mobileHeaderHeight)};

  ${mediaQueries.tablet} {
    padding-top: ${rem(72)};
  }

  ${mediaQueries.desktop} {
    padding-top: ${rem(80)};
  }
`

interface ProjectListProps {
  mapHeight: number
}

const ProjectList = styled(motion.div)<ProjectListProps>`
  grid-column: 1 / span ${gridColumnsRoot};
  width: 100%;
  padding-top: ${({ mapHeight }): string => rem(mapHeight + mapMargins)};

  ${mediaQueries.tablet} {
    grid-column: -5 / span 4;
    padding-top: ${rem(32)};
  }

  ${mediaQueries.desktop} {
    grid-column: -6 / span 5;
    padding-top: ${rem(40)};
  }
`

export default HomePage
