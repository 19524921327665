import { dotAnimation, mapAnimation, pulseAnimation } from './animations'
import { grid, gridColumnsRoot } from '../../../utils/grid'
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Colors from '../../../tokens/Colors'
import dots from './dots'
import Img from 'gatsby-image'
import { mediaQueries } from '../../../utils/mediaQueries'
import { motion } from 'framer-motion'
import { rem } from '../../../utils/rem'
import styled from 'styled-components'
import { useAppState } from '../../../contexts/app-state/AppStateContext'
import useImageData from '../../../hooks/use-image-data/useImageData'
import useWindowSize from '../../../hooks/use-window-size/useWindowSize'

interface MapProps {
  activeProject: string | null
  className?: string
  onHeightChange?: (height: number) => void
}

const Map: FunctionComponent<MapProps> = ({
  className,
  onHeightChange,
  activeProject,
}) => {
  const gridRef = useRef<HTMLDivElement | null>(null)
  const image = useImageData('map.jpg')
  const [windowWidth, windowHeight] = useWindowSize()
  const [{ isIntroViewed }] = useAppState()

  const getMaxHeight = useCallback(() => {
    if (gridRef.current) {
      return gridRef.current.clientHeight
    }

    return null
  }, [windowHeight, windowWidth, gridRef.current])

  const [maxHeight, setMaxHeight] = useState<number | null>(getMaxHeight())
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (wrapperRef.current && image) {
      const newMaxHeight = getMaxHeight() || 0

      onHeightChange &&
        onHeightChange(
          Math.min(
            newMaxHeight,
            wrapperRef.current.clientWidth / image.fluid.aspectRatio,
          ),
        )
      setMaxHeight(newMaxHeight)
    }
  }, [image, windowHeight, windowWidth, wrapperRef])

  return (
    <StyledMapGrid className={className} ref={gridRef}>
      <Wrapper
        animate={isIntroViewed ? ['opacityIn', 'transformIn'] : 'initial'}
        exit="initial"
        ref={wrapperRef}
        variants={mapAnimation}
      >
        {image && (
          <Img
            fluid={image.fluid}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '0 50%',
            }}
            style={{ maxHeight }}
          />
        )}
        {image && (
          <DotWrapper maxHeight={maxHeight} ratio={image.fluid.aspectRatio}>
            {dots.map(({ project, x, y }) => (
              <Fragment key={`dot-${project}`}>
                <Pulse
                  animate={
                    project === activeProject
                      ? ['transformIn', 'opacityIn']
                      : ['transformOut', 'opacityOut']
                  }
                  left={x}
                  top={y}
                  variants={pulseAnimation}
                />
                <Dot
                  animate={
                    project === activeProject ? 'transformIn' : 'transformOut'
                  }
                  left={x}
                  top={y}
                  variants={dotAnimation}
                />
              </Fragment>
            ))}
          </DotWrapper>
        )}
      </Wrapper>
    </StyledMapGrid>
  )
}

const StyledMapGrid = styled.div`
  ${grid()};
  background: ${Colors.BLACK};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  margin: ${rem(32)} 0 0;
  box-sizing: border-box;
  padding-top: ${rem(64)};

  ${mediaQueries.tablet} {
    align-items: center;
    height: calc(100vh - ${rem(152)});
    padding-top: 0;
    top: 0;
    transform: translate(-50%, 0);
    margin: ${rem(120)} 0 0;
  }
`

const Wrapper = styled(motion.div)`
  position: relative;
  grid-column: 1 / span ${gridColumnsRoot};
  height: fit-content;

  ${mediaQueries.tablet} {
    grid-column: 1 / span 5;
  }

  ${mediaQueries.desktop} {
    grid-column: 1 / span 11;
  }
`

interface DotWrapperProps {
  maxHeight: number | null
  ratio: number
}

const DotWrapper = styled.div<DotWrapperProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: ${({ maxHeight, ratio }): string =>
    maxHeight ? rem(maxHeight * ratio) : '100%'};
  top: 0;
  left: 0;
`

interface PulseProps {
  left: number
  top: number
}

const Pulse = styled(motion.div)<PulseProps>`
  width: ${rem(8)};
  height: ${rem(8)};
  border-radius: ${rem(4)};
  background: ${Colors.WHITE};
  position: absolute;
  top: ${({ top }): string => `${top}%`};
  left: ${({ left }): string => `${left}%`};
`

interface DotProps {
  left: number
  top: number
}

const Dot = styled(motion.div)<DotProps>`
  width: ${rem(8)};
  height: ${rem(8)};
  border-radius: ${rem(4)};
  background: ${Colors.WHITE};
  position: absolute;
  top: ${({ top }): string => `${top}%`};
  left: ${({ left }): string => `${left}%`};
`

export default Map
